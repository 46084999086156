header {
  margin-top: 3rem;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.header-content .container.grid-2 {
  grid-template-columns: 2.5fr 2.5fr;
  min-height: 735px;
  text-align: left;
  display: grid;
}

.header-btn {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
}

.header-title, .explore-text {
  margin-top: 3rem;
  font-size: 3rem;
  line-height: 1;
  color: var(--light-one);
  overflow-x: visible;
  z-index: 3;
}

.explore-text{
  font-family: var(--ff-teko);
  text-align: center;
}

.header-content .description-text {
  margin: 2.15rem 0;
}

.header-content .image .img-element {
  max-width: 750px;
  z-index: -1;
}

.intro-img {
  display: flex;
  flex-direction: column;
}

.intro-cmnt {
  justify-self: end;
  margin-left: 25rem;
  color: var(--hover-orange);
}

header .points1 {
  width: 420px;
  bottom: -75px;
  left: -150px;
}

header .points2 {
  width: 70%;
  top: 65%;
  left: 71%;
}

header .square {
  right: 68%;
  top: 71%;
}

header .triangle {
  right: 7%;
  bottom: 75%;
}

header .xshape {
  right: 4%;
  bottom: 50%;
}

header .half-circle1 {
  left: 50%;
  bottom: 82%;
}

header .half-circle2 {
  left: 5%;
  top: 67%;
}

header .wave1 {
  bottom: 75%;
  left: 20%;
}

header .wave2 {
  bottom: 8%;
  right: 55%;
}

header .circle {
  left: 38%;
  bottom: 63%;
}

.letters {
  position: absolute;
  width: 13.5%;
  top: -5px;
  left: 15px;
  opacity: 0.06;
}

.description-text {
  color: var(--light-two);
  transition: 0.3s;
  font-size: 1.25rem;
  line-height: 1.6;
}

.social-media {
  display: flex;
  gap: 1rem;
  margin: 0.75rem;
}

.social-icon .social-svg:hover {
  background-color: var(--hover-orange);
}

.line {
  margin-top: 2rem;
  width: 200px;
  background: var(--hover-orange);
  border: 0.1rem solid var(--hover-orange);
}

@media (max-width: 1200px) {
  .header-content .container.grid-2 {
    display: flex;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .header-content .portfolio-work{
    visibility: hidden;
  }
}

.header-title {
  animation: jumpFromAbove 2s ease-out forwards;
  opacity: 1; /* Ensure it's visible from the start */
}

/* Natural Jump animation for header-title */
@keyframes jumpFromAbove {
  0% {
    transform: translateY(-150px); /* Start much higher (from -150px) */
  }
  20% {
    transform: translateY(20px); /* First jump down */
  }
  40% {
    transform: translateY(-10px); /* Slight bounce up */
  }
  50% {
    transform: translateY(10px); /* Second jump down */
  }
  60% {
    transform: translateY(-5px); /* Slight bounce up */
  }
  70% {
    transform: translateY(5px); /* Third jump down */
  }
  80% {
    transform: translateY(-2px); /* Small bounce */
  }
  90% {
    transform: translateY(1px); /* Small jump down */
  }
  100% {
    transform: translateY(0); /* Settle at the final position */
  }
}
