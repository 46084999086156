nav {
  width: 100%;
  position: relative;
  z-index: 50;
}

nav .container {
  display: flex;
  justify-content: space-between;
  height: 6rem;
  align-items: center;
}

.logo {
  width: 100px;
  display: flex;
  align-items: center;
}

.links ul {
  display: flex;
}

.links a {
  display: inline-block;
  padding-left: 3rem;
  color: var(--light-one);
  text-transform: uppercase;
  font-weight: 400;
  font-family: var(--ff-teko);
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  transition: 0.3s;
}

.links a.active {
  background-color: var(--light-color);
  color: var(--light-one);
  border-radius: 2rem;
  font-size: 1rem;
  padding: 0.9rem 2.1rem;
  margin-left: 1rem;
}

.links a:hover {
  color: var(--hover-orange);
}

.links a.active:hover {
  color: var(--light-one);
  background-color: var(--hover-color);
}

.hamburger-menu {
  width: 2.7rem;
  height: 3rem;
  z-index: 100;
  position: relative;
  display: none;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.hamburger-menu .bar {
  position: relative;
  width: 2.1rem;
  height: 3px;
  border-radius: 3px;
  background-color: var(--light-two);
  transition: 0.5s;
}

.bar:before,
.bar:after {
  content: "";
  position: absolute;
  width: 2.1rem;
  height: 3px;
  border-radius: 3px;
  background-color: var(--light-one);
  transition: 0.5s;
}

.bar:before {
  transform: translateY(-9px);
}

.bar:after {
  transform: translateY(9px);
}

nav.open .hamburger-menu .bar {
  background-color: transparent;
  transform: rotate(360deg);
}

nav.open .hamburger-menu .bar:before {
  transform: translateY(0) rotate(45deg);
  background-color: var(--light-one);
}

nav.open .hamburger-menu .bar:after {
  transform: translateY(0) rotate(-45deg);
  background-color: var(--light-one);
}

nav.open .links {
  transform: translateX(0);
}

nav.open .links {
  background: linear-gradient(rgba(25, 1, 1, 0.9), rgba(6, 0, 0, 0.1)),
    url("https://github.com/pvyas033/host/blob/master/img/img-1.jpg?raw=true") center/cover no-repeat;
}

.links .option {
  color: rgb(249, 249, 249);
}
