.outside-work {
  margin-left: 3rem;
}

.notebook-title {
  color: var(--light-two);
  font-size: 3rem;
  font-family: var(--ff-teko);
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 4rem;
  width: 16rem;
}

.notebook {
  display: flex;
  height: 100%;
  margin: 1rem 2rem;
  border-radius: 5%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1)),
    url("https://github.com/pvyas033/host/blob/master/img/img-2.jpg?raw=true") center/cover no-repeat;
}

.check-out-btn {
  margin: 2rem auto;
}

.outside-work > * {
  animation: dropFromTop 1s ease-out forwards; /* Only the drop animation */
  opacity: 0; /* Start with opacity 0 to allow fade-in */
}

/* Drop animation */
@keyframes dropFromTop {
  0% {
    transform: translateY(-100%); /* Starts off-screen above */
    opacity: 0; /* Starts invisible */
  }
  100% {
    transform: translateY(0); /* Moves to normal position */
    opacity: 1; /* Fades in */
  }
}