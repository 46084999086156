.contact{
  height: 783px;
}

.contact-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 6rem;
}

.contact-button-panel {
  display: flex;
  flex-direction: column;
}

.notebook-add {
  padding: 3rem;
}

.contact-info {
  color: var(--light-one);
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.email {
  font-size: 1rem;
  
}

.icon {
  size: 100rem;
}

.drop-message,
.download-resume {
  margin-bottom: 2rem;
  width: 70%;
  margin-top: 0;
  text-align: center;
}

@media (max-width: 992px) {
  .email {
    font-size: 0.8rem;
  }
  .contact-body {
    grid-template-columns: auto;
  }
  .notebook-add {
    padding: 0;
  }
}

@media (max-width: 776px) {
  .contact {
    height: 100%;
  }
  .drop-message,
  .download-resume {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .email {
    font-size: 0.6rem;
  }
}
