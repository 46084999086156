@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap");



:root {
  /* Background and foreground colors */
  --background-color: #181818;   /* Dark black background */
  --foreground-color: #fff;      /* White foreground */

  /* Shades of gray */
  --dark-one: #333;
  --dark-two: #7a7a7a;
  --main-black: #181818;
  --light-one: #f9fafb;
  --light-two: #f6f7fb;
  --light-three: #f6f7fb;

  /* Hover colors */
  --hover-color: #162b64;        /* Dark blue hover */
  --hover-orange: #cfaa5a;       /* Dark orange hover */

  /* Font family */
  --ff-teko: "Teko", sans-serif;
}



.app {
  background: var(--main-black);
}

/* Genral Styles */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.container {
  position: relative;
  z-index: 5;
  max-width: 92rem;
  padding: 0 4rem;
  margin: 0 auto;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 1.25rem;
  color: var(--dark-two);
  line-height: 1.6;
}

.column-1 {
  margin-right: 1.5rem;
}

.column-2 {
  margin-left: 1.5rem;
}

.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.z-index {
  position: relative;
  z-index: 2;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.overlay .shape {
  width: initial;
  opacity: 0.13;
  position: absolute;
}

.overlay.overlay-lg .shape {
  height: 55px;
}

.overlay.overlay-lg .shape.wave {
  height: initial;
  width: 88px;
}

.overlay.overlay-lg .shape.xshape {
  height: 38px;
}

.overlay.overlay-sm .shape {
  filter: brightness(0) invert(1);
  opacity: 0.15;
  height: 40px;
}

.overlay.overlay-sm .shape.wave {
  height: initial;
  width: 70px;
}

.overlay.overlay-sm .shape.xshape {
  height: 30px;
}

.points {
  opacity: 0.3;
  position: absolute;
}

.section {
  padding: 7rem 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.title {
  color: var(--light-one);
  position: relative;
  display: inline-block;
  padding-bottom: 1rem;
  line-height: 1;
  font-size: 2.8rem;
  margin-bottom: 0.6rem;
}

.title:before {
  content: attr(data-title);
  display: block;
  margin-bottom: 0.4rem;
  color: var(--dark-two);
  font-size: 1.15rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.title:after {
  content: "";
  position: absolute;
  width: 90px;
  height: 5px;
  border-radius: 3px;
  background-color: var(--dark-two);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.section-header .text {
  max-width: 600px;
  margin: 0 auto;
}

.title-sm {
  color: var(--dark-one);
  font-weight: 600;
  font-size: 1.6rem;
}

.points-sq {
  width: 210px;
}

.btn {
  display: inline-block;
  padding: 0.85rem 2rem;
  color: var(--light-one);
  letter-spacing: 0.1rem;
  font-family: var(--ff-teko);
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  border: #fff solid 0.12rem;
  transition: 0.3s;
}

.btn:hover {
  background-color: var(--light-one);
  color: var(--main-black);
  border: var(--hover-orange) solid 0.12rem;
}

.btn.small {
  padding: 0.7rem 1.8rem;
  font-size: 1rem;
}

/* End Genral Styles */

/* Responsive */

@media (max-width: 1280px) {
  .text {
    font-size: 1rem;
  }

  .title {
    font-size: 2.1rem;
    padding-bottom: 0.65rem;
  }

  .title:before {
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
    letter-spacing: 2px;
  }

  .title:after {
    height: 3.5px;
    width: 70px;
  }

  .title-sm {
    font-size: 1.2rem;
  }

  .btn {
    padding: 0.75rem 1.7rem;
    font-size: 0.8rem;
  }

  .btn.small {
    padding: 0.65rem 1.6rem;
    font-size: 0.7rem;
  }

  .section-header .text {
    max-width: 500px;
  }

  .section {
    padding: 4.5rem 0;
  }

  .column-1 {
    margin-right: 1rem;
  }

  .column-2 {
    margin-left: 1rem;
  }

  .overlay.overlay-lg .shape {
    height: 35px;
  }

  .overlay.overlay-lg .shape.wave {
    height: initial;
    width: 63px;
  }

  .overlay.overlay-lg .shape.xshape {
    height: 24px;
  }

  .overlay.overlay-sm .shape {
    height: 24px;
  }

  .overlay.overlay-sm .shape.wave {
    height: initial;
    width: 39px;
  }

  .overlay.overlay-sm .shape.xshape {
    height: 19px;
  }

  header .points1 {
    width: 270px;
    bottom: -50px;
    left: -75px;
  }

  header .points2 {
    width: 340px;
  }

  header .letters {
    width: 11%;
  }

  .header-content .image .img-element {
    max-width: 500px;
  }

  nav .container {
    height: 4.5rem;
  }

  .header-content .container.grid-2 {
    min-height: calc(100vh - 4.5rem);
    padding-bottom: 1.5rem;
  }

  .logo {
    width: 65px;
  }

  .links a {
    font-size: 1.5rem;
    padding: 0.65rem 0.7rem;
  }

  .links a.active {
    font-size: 0.7rem;
    padding: 0.7rem 1.7rem;
    margin-left: 0.6rem;
  }

  .header-title {
    font-size: 2.6rem;
  }

  .header-content .text {
    margin: 1.1rem 0;
  }

  .card {
    min-height: 390px;
    max-width: 290px;
    padding: 2.2rem 1.5rem;
  }

  .icon {
    width: 65px;
    margin-bottom: 0.8rem;
  }

  .card .text {
    font-size: 0.9rem;
    margin: 1rem 0;
  }

  .card:before {
    font-size: 5rem;
  }

  .card-wrap {
    margin: 1rem 0.6rem;
  }

  .services .points1 {
    left: -60px;
  }

  .points-sq {
    width: 150px;
  }

  .grid {
    margin: 0.8rem 0;
  }

  .grid-item {
    padding: 0.8rem;
  }

  .gallery-image {
    height: 250px;
    max-width: 325px;
  }

  .gallery-image .img-overlay {
    padding: 1.4rem 2rem;
  }

  .plus:before,
  .plus:after {
    width: 2.8rem;
  }

  .img-overlay h3 {
    font-size: 1.1rem;
  }

  .img-overlay h5 {
    font-size: 0.8rem;
  }

  .background-bg {
    height: 370px;
  }

  .filter-btn {
    padding: 0.6rem 1.45rem;
    font-size: 0.7rem;
  }

  .about img {
    max-width: 420px;
  }

  .about .text {
    margin-top: 0.7rem;
  }

  .skills {
    margin: 0.9rem 0 1.7rem 0;
  }

  .skill-title {
    font-size: 1rem;
  }

  .skill-bar {
    height: 6px;
  }

  .about .column-1:before {
    font-size: 6rem;
    top: 13px;
  }

  .about .points {
    width: 230px;
    left: 65%;
  }

  .record-circle {
    width: 150px;
    height: 150px;
    border-width: 3px;
  }

  .record-circle .number {
    font-size: 1.8rem;
  }

  .record-circle .sub-title {
    font-size: 0.9rem;
  }

  .blog-wrap {
    margin: 0.7rem 0.5rem;
  }

  .blog-card {
    max-width: 315px;
  }

  .blog-image {
    height: 200px;
  }

  .blog-content {
    padding: 1.8rem 1.7rem 1.9rem 1.7rem;
  }

  .blog-info {
    margin-bottom: 0.2rem;
  }

  .blog-info > * {
    font-size: 0.75rem !important;
  }

  .blog-user i {
    font-size: 0.8rem;
  }

  .blog-text {
    font-size: 0.9rem;
    margin: 0.2rem 0 0.8rem 0;
  }

  .rate {
    font-size: 1.15rem;
    margin-bottom: 1.1rem;
  }

  .review-text {
    font-size: 1.05rem;
  }

  .review-info {
    margin-top: 1.1rem;
  }

  .review-name {
    font-size: 1.05rem;
  }

  .review-job {
    font-size: 0.85rem;
  }

  .quote {
    font-size: 6.5rem;
  }

  .review-nav {
    font-size: 1.55rem;
  }

  .swiper-button-next {
    right: 2.5rem;
  }

  .swiper-button-prev {
    right: 4.5rem;
  }

  .contact {
    padding: 9rem 0 6rem 0;
  }

  .contact-form {
    padding-left: 1rem;
  }

  .contact-info {
    padding-right: 1rem;
  }

  .contact-box {
    padding: 3.6rem 3rem;
  }

  .information-wrap {
    margin-top: 1.2rem;
  }

  .contact-icon {
    min-width: 50px;
    height: 50px;
    font-size: 1rem;
    margin-right: 0.8rem;
  }

  .contact-icon i {
    line-height: 50px;
  }

  .info-text {
    font-size: 1.05rem;
  }

  .contact-input {
    font-size: 0.9rem;
    padding: 0.9rem 1.6rem;
    margin: 0.4rem 0;
  }

  .hireme {
    padding: 8rem 0;
  }

  .footer {
    padding: 4rem 0;
  }

  .footer-links a {
    font-size: 1rem;
  }

  .footer .title-sm {
    margin-bottom: 0.5rem;
  }

  .footer-input-wrap {
    height: 36px;
    margin-top: 0.8rem;
  }

  .footer-input {
    font-size: 0.9rem;
    padding: 0 1.2rem;
  }

  .input-arrow {
    font-size: 1.2rem;
  }

  .copyright .text {
    font-size: 0.95rem;
  }

  .followme h3 {
    font-size: 1.05rem;
  }

  .followme .footer-line {
    margin: 0 0.8rem;
  }

  .social-media a {
    font-size: 1.05rem;
  }

  .back-btn-wrap {
    margin-left: 1.3rem;
  }

  .back-btn {
    width: 43px;
    height: 43px;
    font-size: 0.9rem;
  }

  .back-btn-wrap:before {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 850px) {
  .header-content .image .img-element {
    display: none;
  }

  .grid-2 {
    grid-template-columns: 1fr !important;
  }

  .column-1 {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .column-2 {
    margin-left: 0;
    margin-top: 1rem;
  }

  .hamburger-menu {
    display: flex;
    background-color: var(--main-black);
    justify-content: center;
  }

  .header-content {
    margin-top: 1rem;
  }

  .header-title {
    font-size: 2.3rem;
  }

  .header-content .image {
    max-width: 400px;
    margin: 0 auto;
  }

  header .column-1 {
    max-width: 550px;
    margin: 0 auto;
  }

  .links {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    /* background-color: #252525; */
    background-color: var(--light-one);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: translateX(100%);
    transition: 0.5s;
  }

  .links ul {
    flex-direction: column;
  }

  .links a {
    color: var(--main-black);
    font-size: 2rem;
  }

  .links a.active {
    margin-left: 0;
    margin: 0.5rem 0;
  }

  .header-content .container.grid-2 {
    min-height: 80vh;
  }

  .letters,
  header .half-circle2,
  header .points1,
  header .wave2 {
    display: none;
  }

  header .half-circle1 {
    bottom: 55%;
    left: 23%;
  }

  header .circle {
    left: 8%;
    bottom: 40%;
  }

  header .wave1 {
    bottom: 87%;
    left: 75%;
  }

  header .square {
    top: initial;
    bottom: 5%;
    left: 13%;
  }

  header .triangle {
    right: 14%;
    bottom: 63%;
  }

  header .points2 {
    width: 280px;
    bottom: -10px;
    left: 60%;
  }

  header .xshape {
    left: 90%;
    top: 54%;
  }

  .grid-item {
    width: 50%;
  }

  .gallery-image {
    height: 210px;
    max-width: 270px;
  }

  .background-bg {
    height: 340px;
  }

  .portfolio .half-circle1,
  .portfolio .triangle,
  .portfolio .half-circle2 {
    display: none;
  }

  .portfolio .square {
    top: 10%;
    left: 14%;
  }

  .portfolio .wave {
    top: 33%;
  }

  .portfolio .circle {
    right: 20%;
  }

  .portfolio .xshape {
    top: 25%;
  }

  .skill-bar {
    width: 100%;
  }

  .about .column-1:before {
    font-size: 5rem;
  }

  .about .points {
    top: 60%;
    left: 59%;
  }

  .records .wave1,
  .records .triangle {
    display: none;
  }

  .records .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .records .wave2 {
    left: 48%;
  }

  .records .sqaure2 {
    bottom: 45%;
    right: 50px;
  }

  .testi-content {
    margin-top: 1.7rem;
  }

  .testi-content .image {
    max-width: 500px;
    margin: 0 auto;
  }

  .review-nav {
    bottom: 3rem;
  }

  .contact-box {
    grid-template-columns: 1fr;
    padding: 3.2rem 2.7rem;
  }

  .contact-info {
    padding-right: 0;
    padding-bottom: 0.5rem;
  }

  .contact-form {
    padding-left: 0;
    padding-top: 0.5rem;
  }

  .information-wrap {
    margin-top: 0.7rem;
  }

  .information:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  .contact-input.textarea {
    min-height: 220px;
  }

  .contact:before {
    height: 25%;
  }

  .contact:after {
    height: 75%;
    top: 25%;
  }

  .footer .grid-4 {
    grid-template-columns: repeat(2, 1fr);
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  .footer-about {
    grid-column: 1 / 3;
  }

  .footer-newstletter {
    grid-column: 1 / 3;
  }

  .grid-4-col {
    margin: 1rem 0;
    padding: 0;
  }

  .footer-input-wrap {
    max-width: 300px;
    margin: 0.95rem auto 0 auto;
    grid-template-columns: auto 45px;
  }

  .bottom-footer {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 1.5rem 0 0 0;
    margin-top: 2.5rem;
    position: relative;
  }

  .bottom-footer:before {
    content: "";
    position: absolute;
    width: 90%;
    max-width: 500px;
    height: 1px;
    background-color: #7b7b7b;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .followme-wrap {
    margin-top: 0.8rem;
  }
}

@media (max-width: 600px) {
  .grid-item {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .container {
    padding: 0 2rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .text {
    font-size: 0.92rem;
  }

  .overlay.overlay-lg .shape {
    height: 28px;
  }

  .overlay.overlay-lg .shape.wave {
    height: initial;
    width: 52px;
  }

  .overlay.overlay-lg .shape.xshape {
    height: 22px;
  }

  .records .container {
    grid-template-columns: 1fr;
  }

  .records .half-circle {
    top: 50%;
    left: 50px;
  }

  .records .wave2 {
    bottom: 50%;
    right: 20px;
    left: initial;
  }

  .records .xshape {
    right: 70px;
  }

  .records .square2 {
    bottom: 90px;
    right: 40px;
  }

  .testi-content {
    margin-top: 1rem;
  }

  .review {
    padding: 1.5rem 1rem;
  }

  .review-text {
    font-size: 0.9rem;
  }

  .review-nav {
    bottom: 1.5rem;
    font-size: 1.3rem;
  }

  .swiper-button-next {
    right: 1.3rem;
  }

  .swiper-button-prev {
    right: 3rem;
  }

  .contact {
    padding: 8rem 0 5rem 0;
  }

  .contact-box {
    padding: 2.3rem 2rem;
    border-radius: 30px;
  }

  .contact:before {
    background-size: 200%;
  }

  .contact-form .row {
    grid-column-gap: 0.3rem;
  }

  .contact-input {
    font-size: 0.8rem;
    padding: 0.8rem 1.4rem;
    margin: 0.25rem 0;
  }
}

/* End Responsive */
