.footer-section{
  min-height: 300px;
}

.footer {
  display: grid;
  justify-content: center;
  margin-top: 2rem;
  padding-top: 2rem;
} 

.bottom-footer {
  width: 100cw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
}

.copyright {
  padding: 0 1rem;
  height: 100%;
  width: 100%;
}

.footer-text {
  text-align: center;
  font-family: var(--ff-teko);
  letter-spacing: 0.04rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 2rem;
  color: rgb(111, 167, 120);
}

.chat-btn {
  color: var(--light-one);
  background: var(--main-black);
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  width: 100%;
  visibility: hidden;
}

.chat-btn:hover {
  color: var(--light-one);
}

.work-together {
  width: 100%;
  padding: 0 1rem;
  height: 100%;
  text-align: center;
}

.email-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-name {
  color: var(--light-two);
}

@media (max-width: 900px) {
  .bottom-footer {
    grid-template-columns: 1fr;
  }

  .chat-btn{
    visibility: none;
  }
}

@media (max-width: 900px) {
  .copyright {
    height: 50vh;
  }

  .email-body {
    height: 50vh;
  }

  .footer-text {
    margin: 0;
  }

  .footer {
    margin: 0;
    padding: 0;
  }
}
