.project {
  min-height: 60vh;
  margin: 1rem 0.5rem;
  color: var(--light-two);
  padding-left: 2rem;
  padding-top: 3rem; background: var(--main-black);
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(244, 243, 243, 0.1);
  overflow-y: hidden;
  justify-content: center;
}

.project-stack-header {
  display: flex;
  justify-content: space-between;
}

.project-stack {
  color: var(--dark-two);
  text-transform: uppercase;
  font-family: var(--ff-teko);
  font-size: 2rem;
  justify-content: flex-start;
}

.git-fork {
  justify-self: flex-end;
  color: var(--light-one);
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.project-title {
  text-transform: uppercase;
  line-height: 4rem;
  margin: 3rem 0;
  margin-top: 4rem;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  font-family: var(--ff-teko);
}

.view-site {
  margin-top: 3rem;
  width: 80%;
  text-align: center;
}

@media (max-width: 700px) {
  .project {
    max-height: 100vh;
  }

  .project-title {
    font-size: 3.5rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  .view-site {
    margin-bottom: 3rem;
  }
}

@media (max-width: 400px) {
  .view-site {
    width: 12rem;
  }
}
