.readme {
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.modelcontainer {
  width: 80%;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.modelcontainer h1, h2, h3 {
  color: var(--light-one);
  margin-top: 20px;
  margin-bottom: 20px;
}

.modelcontainer p{
  margin: 20px;
}

.modelcontainer ol{
  margin-left: 30px;
}

.modelcontainer ul{
  margin-left: 30px;
}

pre {
  background: var(--dark-one);
  color: var(--foreground-color);
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  margin: 20px;
}

code {
  color: var(--hover-orange);  
  padding: 2px 4px;
  border-radius: 3px;
}

.navigate-btn{
  position: absolute;
  top: 25px;
  right: 0.2%;
  background: var(--dark-one);
  color: var(--hover-orange); 
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.download-btn {
  background: var(--dark-one);
  color: var(--hover-orange); 
  padding: 15px 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.download-btn:hover {
  background: orange;
  color: black;
}

.navigate-btn:hover {
  background: orange;
  color: black;
}