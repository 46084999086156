

.projects-section {
    margin-top: 5rem;
    justify-content: center;
    display: grid;
    scroll-behavior: smooth;
    max-height: 640px;
    overflow-y: auto;
    padding: 1rem;
  }
  
  .projects {
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    scroll-behavior: smooth;
  }
  
  .projects > * {
    animation: dropFromTop 1s ease-out forwards; /* Only the drop animation */
    opacity: 0; /* Start with opacity 0 to allow fade-in */
  }
  
  /* Drop animation */
  @keyframes dropFromTop {
    0% {
      transform: translateY(-100%); /* Starts off-screen above */
      opacity: 0; /* Starts invisible */
    }
    100% {
      transform: translateY(0); /* Moves to normal position */
      opacity: 1; /* Fades in */
    }
  }

  @media (max-width: 900px){
    .projects-section{
      margin: 0;
      max-height: 800px;
    }
  
    .projects{
      margin: 1rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);  /* Creates three equal-width columns */
    }
  }

  @media (max-width: 700px) {
    .projects-section{
      margin: 0;
      max-height: 1000px;
    }
  
    .projects{
      margin: 1rem;
      display: grid;
      grid-template-columns: repeat(1, 1fr); 
    }
  }
  