/* src/components/SevenChecker.css */
.sevenchecker-container {
    font-family: 'Arial', sans-serif;
    background: linear-gradient(45deg, #ff6f61, #ff9a8b);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70vw; /* Set width to 70% of the viewport */
    height: 50vh; /* Set height to 50% of the viewport for a rectangle */
    max-width: 700px; /* Max width */
    max-height: 400px; /* Max height */
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    margin: 0 auto; /* Ensures horizontal centering */
    position: absolute; /* Position the container absolutely */
    top: 50%; /* Position vertically center */
    left: 50%; /* Position horizontally center */
    transform: translate(-50%, -50%); /* Adjust to perfectly center */
  }
  
  .sevenchecker-container.music-playing {
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .input-container {
    margin-bottom: 20px;
    width: 100%; /* Ensure the input container takes full width */
  }
  
  .input-field {
    padding: 15px;
    font-size: 18px;
    width: 100%; /* Make input field take up all available width */
    box-sizing: border-box;
    border: 2px solid #3498db;
    border-radius: 8px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .input-field:focus {
    border-color: #ff9a8b;
    outline: none;
  }
  
  .result {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    opacity: 0;
    animation: fadeIn 2s ease-out forwards;
  }
  
  .result.thala {
    color: wheat;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
    }
    50% {
      box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.6);
    }
    100% {
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
    }
  }
  