.more-folio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.github-repo-btn{
  width: 50%;
  color: var(--light-one);
  text-align: center;
}

@media (max-width: 900px) {
  .more-folio {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}